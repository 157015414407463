"use strict";

import 'SwipeBounce.js';
import $ from 'jquery';
import * as THREE from 'three';
import * as paper from 'paper';
import {SVGLoader} from 'three/examples/js/loaders/SVGLoader';
import Detector from 'Detector.js';
import TWEEN from '@tweenjs/tween.js';
import {Howl, Howler} from 'howler';
import Promise from 'es6-promise';

// https://tc39.github.io/ecma262/#sec-array.prototype.includes
if (!Array.prototype.includes) {
    Object.defineProperty(Array.prototype, 'includes', {
      value: function(searchElement, fromIndex) {
  
        if (this == null) {
          throw new TypeError('"this" is null or not defined');
        }
  
        // 1. Let O be ? ToObject(this value).
        var o = Object(this);
  
        // 2. Let len be ? ToLength(? Get(O, "length")).
        var len = o.length >>> 0;
  
        // 3. If len is 0, return false.
        if (len === 0) {
          return false;
        }
  
        // 4. Let n be ? ToInteger(fromIndex).
        //    (If fromIndex is undefined, this step produces the value 0.)
        var n = fromIndex | 0;
  
        // 5. If n ≥ 0, then
        //  a. Let k be n.
        // 6. Else n < 0,
        //  a. Let k be len + n.
        //  b. If k < 0, let k be 0.
        var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);
  
        function sameValueZero(x, y) {
          return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y));
        }
  
        // 7. Repeat, while k < len
        while (k < len) {
          // a. Let elementK be the result of ? Get(O, ! ToString(k)).
          // b. If SameValueZero(searchElement, elementK) is true, return true.
          if (sameValueZero(o[k], searchElement)) {
            return true;
          }
          // c. Increase k by 1. 
          k++;
        }
  
        // 8. Return false
        return false;
      }
    });
  }


//import * as dat from 'dat.gui';

if ( ! Detector.webgl ) Detector.addGetWebGLMessage();

var mesh,mesh2,mesh3, skymesh, treemesh, tree, house, housemesh, housetex, citymesh, logomesh, renderer, scene, camera, lookAt;
var camX = 0;
var angle = 0;
var angle2 = [0,0,0,0];
var isPlay = false;
var housetex_counter = 0;
var housetex_counter_delta = 1;
var paths = [];
var current = 0;
var _pos_state = 0;
var last_pos_state = _pos_state;
var _mouseangleX = 0;
var _mouseangleY = 0;
var painter = false;
var debounce_resize = false;
var _audiofiles = [
    // STAGE  LOOP   FILE                           LISTENER                         DELAY VOLUME SINGLEPLAY
    [[0,1,2], true,  'assets/sounds/bg-nature.mp3',         null, 0, 0.5, false],
    [[3],     true,  'assets/sounds/bg-city.mp3',           null, 0, 0.3, false],
    [[0],     false, 'assets/sounds/voice/voice-01.mp3',    null, 1000, 1, true],
    [[1],     false, 'assets/sounds/voice/voice-02.mp3',    null, 1000, 1, true],
    [[2],     false, 'assets/sounds/voice/voice-03.mp3',    null, 1000, 1, true],
    [[3],     false, 'assets/sounds/voice/voice-04.mp3',    null, 1000, 1, true],
    [[0],     true,  'assets/sounds/steps/step-01.mp3',     null, 0, 0.5, false],
    [[1],     true,  'assets/sounds/steps/step-02.mp3',     null, 0, 0.5, false],
    [[2],     true,  'assets/sounds/steps/step-03.mp3',     null, 0, 0.5, false],
    [[3],     true,  'assets/sounds/steps/step-04.mp3',     null, 0, 0.5, false],
    [[9],     false,  'assets/sounds/Arrow-Down-Popup.mp3', null, 0, 0.5, false],
];
let _cam = [
    {
        target: {
            x: 0,
            y: 178,
            z: 0
        },
        cam: {
            x: 0,
            y: 176,
            z: 4
        },    
    },
    {
        target: {
            x: 0,
            y: 100 * 1.05,
            z: 0
        },
        cam: {
            x: 0,
            y: 100,
            z: 15
        },    
    },
    {
        target: {
            x: 0,
            y: 50 * 1.05,
            z: 0
        },
        cam: {
            x: 0,
            y: 50,
            z: 15
        },    
    },
    {
        target: {
            x: 0,
            y: 0,
            z: 0
        },
        cam: {
            x: 0,
            y: 0,
            z: 15
        },    
    },
    {
        target: {
            x: 0,
            y: 110,
            z: -12
        },
        cam: {
            x: 0,
            y: 110,
            z: 1
        },    
    }          
]
var API = {
    tree_x: -0.5,
    tree_y: 72,
    tree_z: -100,
    tree_size: 3,

    house_x: -0.5,
    house_y: 235,
    house_z: -100,
    house_size: 3,
    
    sky_x: 0,
    sky_y: 0,
    sky_z: -1400,

    city_x: 0,
    city_y: -300,
    city_z: -1350,

    mesh_x: 0,
    mesh_y: 110,
    mesh_z: -12,

    mesh2_x: 0,
    mesh2_y: 53.5,
    mesh2_z: -4,

    mesh3_x: 0,
    mesh3_y: 0,
    mesh3_z: -3,

    cloud_x: -600,
    cloud_y: 850,
    cloud_z: -630,
};

function initGui() {
    var gui = new dat.GUI();
    gui.add( API, 'tree_x', -500, 500 ).name( 'Tree: x' ).onChange( updatePos );
    gui.add( API, 'tree_y', -500, 500 ).name( 'Tree: y' ).onChange( updatePos );
    gui.add( API, 'tree_z', -500, 500 ).name( 'Tree: z' ).onChange( updatePos );
    gui.add( API, 'tree_size', 0.1, 5 ).name( 'Tree: size' ).onChange( updatePos );

    gui.add( API, 'house_x', -500, 500 ).name( 'House: x' ).onChange( updatePos );
    gui.add( API, 'house_y', -500, 500 ).name( 'House: y' ).onChange( updatePos );
    gui.add( API, 'house_z', -500, 500 ).name( 'House: z' ).onChange( updatePos );
    gui.add( API, 'house_size', 0.1, 5 ).name( 'House: size' ).onChange( updatePos );


    gui.add( API, 'sky_x', -100, 100 ).name( 'Sky: x' ).onChange( updatePos );
    gui.add( API, 'sky_y', -100, 100 ).name( 'Sky: y' ).onChange( updatePos );
    gui.add( API, 'sky_z', -5000, -200 ).name( 'Sky: z' ).onChange( updatePos );

    gui.add( API, 'city_x', -100, 100 ).name( 'City: x' ).onChange( updatePos );
    gui.add( API, 'city_y', -1500, 1500 ).name( 'City: y' ).onChange( updatePos );
    gui.add( API, 'city_z', -1500, -200 ).name( 'City: z' ).onChange( updatePos );

    gui.add( API, 'mesh_x',  -500, 500).name( 'Tirgel 1: x' ).onChange( updatePos );
    gui.add( API, 'mesh_y',  -500, 500).name( 'Tirgel 1: y' ).onChange( updatePos );
    gui.add( API, 'mesh_z',  -500, 500).name( 'Tirgel 1: z' ).onChange( updatePos );

    gui.add( API, 'mesh2_x', -500, 500).name( 'Tirgel 2: x' ).onChange( updatePos );
    gui.add( API, 'mesh2_y', -500, 500).name( 'Tirgel 2: y' ).onChange( updatePos );
    gui.add( API, 'mesh2_z', -500, 500).name( 'Tirgel 2: z' ).onChange( updatePos );

    gui.add( API, 'mesh3_x', -500, 500 ).name( 'Tirgel 3: x' ).onChange( updatePos );
    gui.add( API, 'mesh3_y', -500, 500 ).name( 'Tirgel 3: y' ).onChange( updatePos );
    gui.add( API, 'mesh3_z', -500, 500 ).name( 'Tirgel 3: z' ).onChange( updatePos );

    gui.add( API, 'cloud_x', -1000, 1000 ).name( 'Cloud: x' ).onChange( updatePos );
    gui.add( API, 'cloud_y', -1000, 1000 ).name( 'Cloud: y' ).onChange( updatePos );
    gui.add( API, 'cloud_z', -1000, 1000 ).name( 'Cloud: z' ).onChange( updatePos );
    

}

function swipedetect(el, callback){
			
    var touchsurface = el,
    swipedir,
    startX,
    startY,
    distX,
    distY,
    threshold = 50, //required min distance traveled to be considered swipe
    restraint = 100, // maximum distance allowed at the same time in perpendicular direction
    allowedTime = 300, // maximum time allowed to travel that distance
    elapsedTime,
    startTime,
    handleswipe = callback || function(swipedir){}

    touchsurface.addEventListener('touchstart', function(e){
        var touchobj = e.changedTouches[0]
        swipedir = 'none'
        startX = touchobj.pageX
        startY = touchobj.pageY
        startTime = new Date().getTime() // record time when finger first makes contact with surface
    }, false)

    touchsurface.addEventListener('touchmove', function(e){
    }, false)

    touchsurface.addEventListener('touchend', function(e){
        var touchobj = e.changedTouches[0]
        distX = touchobj.pageX - startX // get horizontal dist traveled by finger while in contact with surface
        distY = touchobj.pageY - startY // get vertical dist traveled by finger while in contact with surface
        elapsedTime = new Date().getTime() - startTime // get time elapsed
        if (elapsedTime <= allowedTime){ // first condition for awipe met
            if (Math.abs(distX) >= threshold && Math.abs(distY) <= restraint){ // 2nd condition for horizontal swipe met
                swipedir = (distX < 0)? 'left' : 'right' // if dist traveled is negative, it indicates left swipe
            }
            else if (Math.abs(distY) >= threshold && Math.abs(distX) <= restraint){ // 2nd condition for vertical swipe met
                swipedir = (distY < 0)? 'up' : 'down' // if dist traveled is negative, it indicates up swipe
            }
        }
        handleswipe(swipedir)
    }, false)
}

function rhomboid(width, height, depth, bevel) {

    height = height || 12;
    width = width || 8;
    depth = depth || 16;

    var shape = new THREE.Shape();
    shape.moveTo( -width/2,0 );
    shape.lineTo( 0, -height/2 );
    shape.lineTo( width/2, 0 );
    shape.lineTo( 0, height/2 );
    shape.lineTo( -width/2,0 );

    var extrudeSettings = {
        steps: 10,
        depth: depth,
        bevelEnabled: bevel || false,
        bevelThickness: 0.1,
        bevelSize: 0.1,
        bevelSegments: 10
    };
    
    return new THREE.ExtrudeGeometry( shape, extrudeSettings );
}

function updatePos() {


    let _aspect = ((window.innerHeight / window.innerWidth) - 0.66) * 15;


    treemesh.position.x = API.tree_x;
    treemesh.position.z = API.tree_z;
    treemesh.position.y = API.tree_y;
    treemesh.scale.x =  API.tree_size;
    treemesh.scale.y =  API.tree_size;
    treemesh.scale.z =  API.tree_size;

    housemesh.position.x = API.house_x;
    housemesh.position.z = API.house_z;
    housemesh.position.y = API.house_y;
    housemesh.scale.x =  API.house_size;
    housemesh.scale.y =  API.house_size;
    housemesh.scale.z =  API.house_size;

    skymesh.position.x = API.sky_x;
    skymesh.position.y = API.sky_y;
    skymesh.position.z = API.sky_z;
    skymesh.rotation.x = 0.3;
    citymesh.position.x = API.city_x;
    citymesh.position.y = API.city_y;
    citymesh.position.z = API.city_z;
    mesh.position.x = API.mesh_x;
    mesh.position.y = API.mesh_y;
    mesh.position.z = API.mesh_z - _aspect;
    mesh2.position.x = API.mesh2_x;
    mesh2.position.y = API.mesh2_y;
    mesh2.position.z = API.mesh2_z - _aspect;
    mesh3.position.x = API.mesh3_x;
    mesh3.position.y = API.mesh3_y;
    mesh3.position.z = API.mesh3_z - _aspect;

    if (logomesh && logomesh.position) {
        let _f = (1 + ((window.innerWidth / window.innerHeight) - 1.5) / 1.5);
        if (_f > 1) _f = 1;
        logomesh.position.x = API.cloud_x * ((.9 + (_f/10)) * _f);
        logomesh.position.y = API.cloud_y;
        logomesh.position.z = API.cloud_z;
        logomesh.scale.x  = .9 * _f;
        logomesh.scale.y  = -logomesh.scale.x;
    }
}

function loadAudio() {
    var p = new Promise(function(resolve, reject) {
        var _sndloaded = 0;
        var _sndcount = function() {
            _sndloaded++;
            //console.log(`SND: ${_sndloaded}`);
            if (_sndloaded == 11) {
                resolve(true);
            }
        }


        _audiofiles.forEach((_af,i) => {

            _af[3] = new Howl({
                src: _af[2],
                autoplay: false,
                loop: _af[1],
                volume: _af[5],
                onend: function() {
                    if (_af[1] === false && _af[0][0] !== 9) {
                        showContinue();
                    };
                },
                onload: function() {
                    _af[7] = false;
                    _sndcount();
                }
              });

        });
    });
    return p;
}

function showContinue(mute) {
    mute = mute || false;
    $('#continue').addClass('button-continue-visible').delay(25).queue(function(){
        if (mute === false) {
            _audiofiles[10][3].play();
        }
        $('#continue').addClass('button-continue-visible-bounce')
        $(this).dequeue();
    });
}

function init() {
    var p = new Promise(function(resolve, reject) {
        
        var _texloaded = 0;
        var _texcount = function(tex) {
            _texloaded++;
            console.log(`Tex loaded: ${tex}`);
            if (_texloaded == 15) {
                resolve(true);
            }
        }

        var _texerr = function(err) {
            console.log( `An error happened: ${err}` );
        }


        
    
        // Renderer INIT
        let viewport = $('#space')[0];
        renderer = new THREE.WebGLRenderer({canvas: viewport, antialias: true});
        renderer.setPixelRatio( window.devicePixelRatio );
        renderer.setSize( window.innerWidth, window.innerHeight );
        renderer.shadowMap.enabled = false;
        // Scene and Camera
        scene = new THREE.Scene();
        camera = new THREE.PerspectiveCamera( 50, window.innerWidth / window.innerHeight, 1, 3000 );
        camera.position.set( _cam[0].cam.x, _cam[0].cam.y, _cam[0].cam.z );
        lookAt = new THREE.Vector3( _cam[0].target.x, _cam[0].target.y, _cam[0].target.z);
        camera.lookAt(lookAt);
        scene.add(camera);
    
        var spotLight = new THREE.SpotLight( 0xffffff, .9 );
        spotLight.position.set( 0, 500, 500 );
        var targetObject = new THREE.Object3D();
        targetObject.position.set( 0, 300, 0 );
        scene.add(targetObject);
        spotLight.target = targetObject;
    
        var spotLight2 = new THREE.SpotLight( 0xffffff, .5 );
        spotLight2.position.set( 0, -500, 500 );
        spotLight2.angle = Math.PI;
        spotLight2.penumbra = 0.5;
        var targetObject2 = new THREE.Object3D();
        targetObject2.position.set( 0, 300, 0 );
        scene.add(targetObject2);
        spotLight2.target = targetObject2;
        scene.add( spotLight, spotLight2 );
    
        // Logotype
    
        var loader = new SVGLoader();
        loader.load( 'assets/textures/01-tm.svg', function ( _paths ) {
            
            let _f = (1 + ((window.innerWidth / window.innerHeight) - 1.5) / 1.5);
            if (_f > 1) _f = 1;


            logomesh = new THREE.Group();
            logomesh.scale.x  = .9 * _f;
            logomesh.scale.y  = -logomesh.scale.x;

            for ( var i = 0; i < _paths.length; i ++ ) {
                var path = _paths[ i ];
                var material = new THREE.MeshBasicMaterial( {
                    color: path.color,
                    side: THREE.DoubleSide,
                    depthWrite: false
                } );
                var shapes = path.toShapes( true );
                for ( var j = 0; j < shapes.length; j ++ ) {
                    var shape = shapes[ j ];
                    var geometry = new THREE.ShapeBufferGeometry( shape );
                    var mesh = new THREE.Mesh( geometry, material );
                    logomesh.add( mesh );
                }
            }

            logomesh.position.x = API.cloud_x * ((.9 + (_f/10)) * _f);
            logomesh.position.y = API.cloud_y;
            logomesh.position.z = API.cloud_z;
            logomesh.rotation.x = 0.4;
            scene.add( logomesh );
            _texcount('01-tm.svg')
        }, undefined, _texerr );
    
    
        // Tirgel 0 - Holzbrett
    
        var _front = new THREE.MeshLambertMaterial( {
            map: new THREE.TextureLoader().load( 'assets/textures/holzbrett_aquarell_x2.jpg', _texcount('holzbrett_aquarell_x2.jpg'), undefined, _texerr ),
        });
        var _side = new THREE.MeshLambertMaterial( { 
            map: new THREE.TextureLoader().load( 'assets/textures/holzbrett_aquarell_side_x2.jpg', _texcount('holzbrett_aquarell_side_x2.jpg'), undefined, _texerr ) 
        } );
        mesh = new THREE.Mesh( new THREE.BoxBufferGeometry( 20, 12, 2 ), [_side, _side, _side, _side, _front, _front] );
    
    
        // Tirgel 02 - Holzform
    
        var holzbrett_02 = new THREE.TextureLoader().load( 'assets/textures/02-holzbrett.jpg', _texcount('02-holzbrett.jpg'), undefined, _texerr );
        holzbrett_02.wrapS = holzbrett_02.wrapT = THREE.RepeatWrapping;
        holzbrett_02.repeat.set( .065 , .08 );
        holzbrett_02.offset.set( -0.5, -0.5 );
        var _front_mat_holz02 = new THREE.MeshLambertMaterial( {
            map: holzbrett_02
        });
    
        var holzbrett_02_side = new THREE.TextureLoader().load( 'assets/textures/holzbrett_aquarell_side_x2.jpg', _texcount('holzbrett_aquarell_side_x2.jpg'), undefined, _texerr ) 
        holzbrett_02_side.wrapS = holzbrett_02_side.wrapT = THREE.RepeatWrapping;
        holzbrett_02_side.repeat.set( 0.25 , 0.5 );
        holzbrett_02_side.offset.set( -.5, -.5 );
        var _side_mat_holz02 = new THREE.MeshLambertMaterial( {
            map: holzbrett_02_side
        });
        
        mesh2 = new THREE.Mesh( rhomboid( 15, 12, 2 ), [_front_mat_holz02, _side_mat_holz02] );

        // Tirgel 03 - volle Form
    
        var tirgel_03 = new THREE.TextureLoader().load( 'assets/textures/03-tirgel.jpg', _texcount('03-tirgel.jpg'), undefined, _texerr );
        tirgel_03.wrapS = tirgel_03.wrapT = THREE.RepeatWrapping;
        tirgel_03.repeat.set( .067 , .083 );
        tirgel_03.offset.set( -0.5, -0.5 );
        tirgel_03.rotation = (Math.PI);
        var _front_mat_holz03 = new THREE.MeshLambertMaterial( {
            map: tirgel_03
        });
        var tirgel_03_back = new THREE.TextureLoader().load( 'assets/textures/03-tirgel-back.jpg', _texcount('03-tirgel-back.jpg'), undefined, _texerr );
        tirgel_03_back.wrapS = tirgel_03_back.wrapT = THREE.RepeatWrapping;
        tirgel_03_back.repeat.set( .067 , .083 );
        tirgel_03_back.offset.set( -0.5, -0.5 );
        tirgel_03_back.rotation = (Math.PI);
        var _back_mat_holz03 = new THREE.MeshLambertMaterial( {
            map: tirgel_03_back
        });
        var tirgel_03_side = new THREE.TextureLoader().load( 'assets/textures/03-tirgel-side.jpg', _texcount('03-tirgel-side.jpg'), undefined, _texerr );
        tirgel_03_side.wrapS = tirgel_03_side.wrapT = THREE.RepeatWrapping;
        tirgel_03_side.repeat.set( 0.1 , 1 );
        tirgel_03_side.offset.set( -0.5, -0.5 );
        var _side_mat_holz03 = new THREE.MeshLambertMaterial( {
            map: tirgel_03_side
        });
        mesh3 = new THREE.Mesh( rhomboid( 15, 12, 0.1, true ), [_back_mat_holz03, _side_mat_holz03, _front_mat_holz03] );
        for ( var face in mesh3.geometry.faces ) {
            if (mesh3.geometry.faces[ face ].normal.z == 1) mesh3.geometry.faces[ face ].materialIndex = 2;
        }
        
    
        var sky = new THREE.PlaneBufferGeometry( 30000, 6000 );
        var skymat = new THREE.TextureLoader().load( 'assets/textures/05-sky.jpg', _texcount('05-sky.jpg'), undefined, _texerr );
        skymat.wrapS = skymat.wrapT = THREE.RepeatWrapping;
        skymat.repeat.set( 10 , 1 );
        skymat.offset.set( 0, 0 ); 
        skymesh = new THREE.Mesh( sky, new THREE.MeshBasicMaterial( {
            map: skymat
        } ) );


        
        scene.background = new THREE.Color(255,255,255);
        
    
    
        tree = new THREE.PlaneBufferGeometry( 15, 15 * 5.508776666078984  );
        treemesh = new THREE.Mesh( tree, new THREE.MeshBasicMaterial( {
            map: new THREE.TextureLoader().load( 'assets/textures/04-house-tree.png', _texcount('04-house-tree.png'), undefined, _texerr ), 
            transparent: true,
            color: 0xffffff
        } ) );

        house = new THREE.PlaneBufferGeometry( 15, 15 * 1.771156558533145  );
        housetex = [
            [new THREE.MeshBasicMaterial( {
                map: new THREE.TextureLoader().load( 'assets/textures/04-house-a.png', _texcount('04-house-a.png'), undefined, _texerr ), 
                transparent: true,
                color: 0xffffff
            } ), 6000], 
            [new THREE.MeshBasicMaterial( {
                map: new THREE.TextureLoader().load( 'assets/textures/04-house-b.png', _texcount('04-house-b.png'), undefined, _texerr ), 
                transparent: true,
                color: 0xffffff
            } ), 50], 
            [new THREE.MeshBasicMaterial( {
                map: new THREE.TextureLoader().load( 'assets/textures/04-house-c.png', _texcount('04-house-c.png'), undefined, _texerr ), 
                transparent: true,
                color: 0xffffff
            } ), 50], 
            [new THREE.MeshBasicMaterial( {
                map: new THREE.TextureLoader().load( 'assets/textures/04-house-d.png', _texcount('04-house-d.png'), undefined, _texerr ), 
                transparent: true,
                color: 0xffffff
            } ), 3000]
        ];
        housemesh = new THREE.Mesh( house, housetex[housetex_counter][0] );        
        
    
    
        var city = new THREE.PlaneBufferGeometry( 1330 * 10, 1330 * 0.702304426925409 );
        var city_mat = new THREE.TextureLoader().load( 'assets/textures/01-city.png', _texcount('01-city.png'), undefined, _texerr );
        city_mat.wrapS = city_mat.wrapT = THREE.RepeatWrapping;
        city_mat.repeat.set( 10 , 1 );
        city_mat.offset.set( -1, -1 );
    
    
        citymesh = new THREE.Mesh( city, new THREE.MeshBasicMaterial( {
            map: city_mat, 
            transparent: true,
            color: 0xffffff
        } ) );
    
    
    
    
    
    
        scene.add( 
            mesh, 
            mesh2, 
            mesh3,
            skymesh,
            treemesh,
            citymesh,
            housemesh
        );
    
        window.addEventListener( 'resize', onWindowResize, false );
        window.addEventListener( 'rotate', onWindowResize, false );
        window.addEventListener( 'orientationchange', onOrientation, false );
        
       /* 
        window.addEventListener( 'wheel', onKeydown, false );
        swipedetect(document.body, function(swipedir){
            if (swipedir == "up" || swipedir == "down") {
                onKeydown(swipedir)
            }
        });*/
        
        updatePos();
        //initGui();
        //window.scrollTo(0,1)
    });
    return p;
}

function onOrientation() {
    /*setTimeout(function(){
        onWindowResize()
    },500);*/
}

function houseTexRunner() {
    if (housetex_counter >= housetex.length - 1) {
        housetex_counter_delta = -1;
    }
    if (housetex_counter <= 0) {
        housetex_counter_delta = 1;
    }    

    housetex_counter+=housetex_counter_delta;

    housemesh.material =  housetex[housetex_counter][0];
    setTimeout( function() {
        houseTexRunner()
    }, housetex[housetex_counter][1] );
}

function onMouseMove(e) {
    if (painter === true) return;
    camX  = 0 - (1.5 - (3 / window.innerWidth * e.x));
    lookAt.x = camX * .9;
    camera.position.x = camX;
    _mouseangleX = (Math.PI/4) - (Math.PI/2 / window.innerWidth * e.x);
    _mouseangleY = (Math.PI/4) - (Math.PI/2 / window.innerHeight * e.y);
}

function showPainter() {
    if (painter === true) return;
    $('.painter').addClass('painter-visible').delay(25).queue(function(){
        initDrawApp();
        $('.painter').addClass('painter-visible-bounce');
        painter = true;
        $(this).dequeue();
    });
}

function resizeCanvas(ecanvas, econtainer, escreen) {
    let container = econtainer[0];
    let canvas = ecanvas[0];
    let screen = escreen[0];
    let _fac = screen.clientWidth > 1600 ? 0.2 
                                        : (screen.clientWidth > 736 ? .05 : 0.02);
    let _w = screen.clientWidth - (screen.clientWidth * _fac);
    let _h = screen.clientHeight - (screen.clientHeight * _fac);
    if (_w / 1902 * 1268 <= _h) {
        container.width = canvas.width = _w;
        container.height = canvas.height = _w / 1902 * 1268;    
    }
    else {
        container.width = canvas.width = _h / 1268 * 1902;
        container.height = canvas.height = _h;
    }
    // Set jQuery Element as well...
    ecanvas.width(canvas.width);
    ecanvas.height(canvas.height);
    econtainer.width(canvas.width);
    econtainer.height(canvas.height);    
}

function initDrawApp() {
    resizeCanvas($('.painter-canvas'), $('.painter-container'), $('.painter'))
    paper.setup('custom-board-2');
    var tool = new Tool();
    var constraint = false;
    var constraint_direction = false;
    tool.minDistance = 0;
    tool.maxDistance = 100;
    window.strokeColor = '#000000';
    window.strokeWidth = 2;
    var initPoint;
    paths = [];
    current = 0;
    var _moved;
    var path;
    tool.onMouseDown = function(event) {
        path = new Path({
            shadowColor: window.strokeWidth == 2 ? new Color(0, 0, 0) : null,
            shadowBlur: 10,
            shadowOffset: new Point(10, 10),
            strokeWidth: window.strokeWidth,
            strokeColor: window.strokeColor,
            strokeCap: 'round'
        });
        path.add(event.point);
        initPoint = event.point;
        _moved = event.tool._moveCount;
    }
    tool.onMouseDrag = function(event) {
        if (constraint === true && constraint_direction === false) {
            if (Math.abs(event.event.movementX) > 1 || Math.abs(event.event.movementY) > 1) {
                if (Math.abs(event.event.movementX) >= Math.abs(event.event.movementY)) {
                    constraint_direction = 'X';
                }
                else {
                    constraint_direction = 'Y';
                }
            }
        }
        if (constraint_direction !== false) {
            if (constraint_direction == 'X') {
                path.add(new Point(event.point.x, initPoint.y));
            }
            if (constraint_direction == 'Y') {
                path.add(new Point(initPoint.x, event.point.y));
            }
        }
        else {
            if (constraint === false) {
                path.add(event.point);
            }
        }
    }
    tool.onMouseUp = function(event) {
        if (constraint_direction !== false) {
            if (constraint_direction == 'X') {
                path.add(new Point(event.point.x, initPoint.y));
            }
            if (constraint_direction == 'Y') {
                path.add(new Point(initPoint.x, event.point.y));
            }
            constraint_direction = false;
        }
        else {
            if (constraint === false) {
                path.add(event.point);
            }
        }
        if (event.tool._moveCount - _moved == 0) {
            path = new Path.Circle(event.point, 2);
            path.fillColor = 'black';
        } else {
            path.smooth();
            path.simplify();	
        }
        paths.push(path);
        current = paths.length - 1;
        path.pathIndex = current;
    }
    tool.onKeyDown = function(e) {
        if (e.key === "shift") {
            constraint = true;
        }
    }
    tool.onKeyUp = function(e) {
        if (e.key === "shift") {
            constraint = false;
        }
    }
    paper.view.onResize = function(event) {
        //paper.project.fitBounds(paper.project.activelayer, true);
        //console.log(paper.activelayer);
        // TODO
    }
}

function cancelLastPath() {
    if (paths[current]) {
        paths[current].clear();
        paths = paths.slice(0, -1);
        current = paths.length - 1;
    }
}

function hidePainter() {
    $('.painter').removeClass('painter-visible-bounce').delay(500).queue(function(){
        $('.painter').removeClass('painter-visible')
        painter = false;
        $(this).dequeue();
    });
}

function onKeydown(e) {

    if (isPlay === true || (painter === true && e == 4)) return;

    last_pos_state = _pos_state;


    let _duration = 1000;
    if (e == '0') 
        _pos_state = 0;
    if (e == '1')
        _pos_state = 1;
    if (e == '2')
        _pos_state = 2;
    if (e == '3')
        _pos_state = 3;
    if (e == '4') 
        _pos_state = 4;

    // Steppers

    let _d = false;
    if (e == "up") {
        _d = 1;
    }
    if (e == "down") {
        _d = -1;
    }		
    if (e.deltaY) {
        _d = e.deltaY > 0 ? 1 : -1;
    }
    if (_pos_state > 0 && _d == -1 || _pos_state < 4 && _d == 1) {
        _pos_state += _d;
    } 

    if (_pos_state == 4) {
        showPainter();
    }
    else {
        hidePainter();
    }

    new TWEEN.Tween( lookAt )
        .to( {
            x: _cam[_pos_state].target.x, 
            y: _cam[_pos_state].target.y, 
            z: _cam[_pos_state].target.z 
        }, _duration )
        .easing( TWEEN.Easing.Quadratic.InOut )
        .start();
    new TWEEN.Tween( camera.position )
        .to({
            x: _cam[_pos_state].cam.x, 
            y: _cam[_pos_state].cam.y, 
            z: _cam[_pos_state].cam.z 
        }, _duration )
        .easing( TWEEN.Easing.Quadratic.InOut )
        .onComplete(
            function(){
                isPlay = false;
            }
        )
        .start();

    isPlay = true;
    pageChange(_pos_state);
}

function pageChange(page) {
    //alert(page);
    _audiofiles.forEach(_af => {
        if (_af[3]) {
            if (_af[0].includes(page)) {
                    if (_af[6] === true) {
                        if (!_af[3].playing() && _af[7] === false) {
                            _af[3].play();
                            _af[7] = true;
                        } else {
                            showContinue(true);
                        }
                    }
                    else {
                        if (!_af[3].playing()) {
                            _af[3].play();
                        }
                    }
            }
            else {
                //console.log(_af[3]);
                if (_af[3].playing()) {
                    _af[3].stop();
                }
            }    
        }
    });

    // Buttons

    if (page < 4) {
        $('#draw').addClass('button-draw-visible');
        $('#info').addClass('button-info-visible');
        $('#close').removeClass('button-close-visible');
    }
    if (page == 4) {
        $('#draw').removeClass('button-draw-visible');
        $('#info').removeClass('button-info-visible');
        $('#close').addClass('button-close-visible');
    }
    if (page == 3) {
        window.addEventListener( 'wheel', onKeydown, false );
        swipedetect(document.body, function(swipedir){
            if (swipedir == "up" || swipedir == "down") {
                onKeydown(swipedir)
            }
        });
    }
    angle2[page] = 10 * Math.PI;
}

function animate() {

    angle2.forEach(function(a,i){
        angle2[i] = a > 0.001 ? a - (a/30) : 0;
    });

    angle += 0.01;


/*
    mesh3.rotation.y = Math.sin(angle)  / 3;
    mesh3.rotation.x = Math.tan(angle2) / 5;
    mesh.rotation.y  = Math.sin(angle2) / 3;
    mesh.rotation.x  = Math.cos(angle2) / 5;
    mesh2.rotation.y = Math.sin(angle)  / 5;
    mesh2.rotation.z = Math.cos(angle)  / 5;
*/

    mesh.rotation.y = mesh2.rotation.y = mesh3.rotation.y  = Math.sin(angle) / 3 + _mouseangleX;
    mesh.rotation.x = mesh2.rotation.x = mesh3.rotation.x  = Math.cos(angle) / 5 + _mouseangleY;

    mesh.rotation.x += angle2[1];
    mesh2.rotation.x += angle2[2];
    mesh3.rotation.x += angle2[3];


    render();

    setTimeout( function() {
        requestAnimationFrame( animate );
    }, 1000 / 40 );
}

function render() {
    TWEEN.update();
    camera.lookAt(lookAt);
    renderer.render( scene, camera );
}


function onWindowResize() {
    if (debounce_resize !== false) clearTimeout(debounce_resize);
    debounce_resize = setTimeout(function(){
        updatePos();
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize( window.innerWidth, window.innerHeight );
        debounce_resize = false;
        if (painter === true) {
            resizeCanvas($('.painter-canvas'), $('.painter-container'), $('.painter'))
        }
    }, 500);
}

function onClickHandlers() {
    $('body').on('click', '#bt_clear', function(e){
        e.preventDefault();
        cancelLastPath();
        return false;
    });
    $('body').on('click', '#bt_draw', function(e){
        window.strokeColor = '#000000';
        window.strokeWidth = 2;
        $('#custom-board-2').css('cursor', "url('assets/images/pointer-pencil.svg') 0 29, auto");
        
        $('#bt_del').removeClass('button-rubber-active');
        $(this).addClass('button-pen-active');
        e.preventDefault();
        return false;
    });
    $('body').on('click', '#bt_del', function(e){
        e.preventDefault();
        window.strokeColor = '#FFFFFF';
        window.strokeWidth = 20;
        $('#custom-board-2').css('cursor', "url('assets/images/pointer-rubber.svg') 0 20, auto");
        $('#bt_draw').removeClass('button-pen-active');
        $(this).addClass('button-rubber-active');
        return false;
    });
    $('body').on('click', '#bt_send', function(e){
        e.preventDefault();
        $('.painter-form').removeClass('painter-form-hidden');
        return false;
    })
    $('body').on('click', '#continue', function(e){
        e.preventDefault();
        onKeydown("up");
        $('#continue').removeClass('button-continue-visible-bounce').delay(500).queue(function(){
            $('#continue').removeClass('button-continue-visible')
            $(this).dequeue();
        });
        return false;
    })
    $('body').on('click', '#draw', function(e){
        e.preventDefault();
        onKeydown(4);
        return false;
    })
    $('body').on('click', '#close', function(e){
        e.preventDefault();
        onKeydown(last_pos_state);
        return false;
    })
    $('body').on('click', '#info', function(e){
        e.preventDefault();
        $('.info').addClass('info-visible').delay(25).queue(function(){
            $('.info').addClass('info-visible-bounce')
            $(this).dequeue();
        });
        $('#space').addClass("blurry").delay(25).queue(function(){
            $('#space').addClass('blurry-visible')
            $(this).dequeue();
        });
        $('#draw').removeClass('button-draw-visible');
        $('#info').removeClass('button-info-visible');    
        return false;
    })
    $('body').on('click', '.info, .info > .text', function(e){
        if (e.target.nodeName === 'A' || e.target.nodeName === 'a') {
            return;
        }
        e.preventDefault();
        $('.info').removeClass('info-visible-bounce').delay(500).queue(function(){
            $('.info').removeClass('info-visible')
            $(this).dequeue();
        });
        $('#space').removeClass("blurry-visible").delay(500).queue(function(){
            $('#space').removeClass('blurry');
            $(this).dequeue();
        });
        $('#draw').addClass('button-draw-visible');
        $('#info').addClass('button-info-visible');
        return false;
    })
    $('body').on('click', '.painter', function(e){
        $('.painter-info').fadeOut();
    })
    $('body').on('click', '#start', function(e){
        $('#preloader').addClass('preload-hidden');
        window.addEventListener( 'mousemove', onMouseMove, false);
        pageChange(0);
        setTimeout( function() {
            houseTexRunner()
        }, housetex[0][1] );
    });
    $('body').on('keydown', '#submission input', function(e){
        $(this).removeClass('error');
    });

    $('body').on('submit', '#submission', function(e){
        /*e.preventDefault();
        let _error = false;
        [$("#f_name"),
        $("#f_vorn"),
        $("#f_emai")].forEach((f) => {
            //console.log(f.val());
            if (f.val() == "") {
                f.addClass('error');
                _error = true;
            }
        });

        if (paths.length == 0) {
            $('.painter-info > p').text('Bevor du am Wettbewerb teilnehmen kannst, musst du hier eine Zeichnung machen.')
            $('.painter-info').fadeIn();
            $('.painter-form').addClass('painter-form-hidden');
            _error = true;
            
        }

        if (_error === true) {
            return false;
        }

        $("#f_draw").val(paper.project.exportSVG({asString: true}));    


        var jqxhr = $.post( "./server/form.php", //"/assets/php/gateway.php", 
        $( this ).serialize(),
        function() {
            $('.painter-info > p').text('Vielen Dank fürs mitmachen. Die GewinnerInnen werden per E-Mail benachrichtigt.')
            $('.painter-info').fadeIn();
            $('.painter-form').addClass('painter-form-hidden');

        })
        .fail(function() {
            $('.painter-info > p').text('Ein Fehler ist aufgetreten. Bitte versuch es nochmals.')
            $('.painter-info').fadeIn();
            $('.painter-form').addClass('painter-form-hidden');
        });*/
        return false;
    })
    $('body').on('click', '#closeform', function(e){
        $('.painter-form').addClass('painter-form-hidden');
        e.preventDefault();
        return false;
    })
    $('body').on('click', '#f_submit', function(e){
        $('#submission').submit();
        e.preventDefault();
        return false;
    })
    
}

setTimeout( function() {
    console.log("start init")
    init().then(function(){
        console.log("init done")
        loadAudio().then(function(){
            console.log("audio done")
            onClickHandlers();
            animate();
            paper.install(window);
            $('#preloader').addClass('preload-fade');
            console.log("running");
        });
    });
}, 1);
