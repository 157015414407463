/**
 * @author alteredq / http://alteredqualia.com/
 * @author mr.doob / http://mrdoob.com/
 */

var Detector = {

	canvas: !! window.CanvasRenderingContext2D,
	webgl: ( function () {

		try {

			var canvas = document.createElement( 'canvas' ); return !! ( window.WebGLRenderingContext && ( canvas.getContext( 'webgl' ) || canvas.getContext( 'experimental-webgl' ) ) );

		} catch ( e ) {

			return false;

		}

	} )(),
	webgl2: ( function () {

		try {

			var canvas = document.createElement( 'canvas' ); return !! ( window.WebGL2RenderingContext && ( canvas.getContext( 'webgl2' ) ) );

		} catch ( e ) {

			return false;

		}

	} )(),
	workers: !! window.Worker,
	fileapi: window.File && window.FileReader && window.FileList && window.Blob,

	getWebGLErrorMessage: function () {

		var element = document.createElement( 'div' );
		element.id = 'webgl-error-message';
		
		var supp_browser = 'Tirggelmannli.ch läuft auf neuen Chrome, Safari, Firefox und Opera Browsern, Internet Explorer Edge und IE11.';
		supp_browser += '<br><br>Bitte updaten Sie ihr System.';

		if ( ! this.webgl ) {

			element.innerHTML = window.WebGLRenderingContext ? [
				'<div>Ihre Grafikkarte scheint <a href="http://khronos.org/webgl/wiki/Getting_a_WebGL_Implementation>WebGL</a> nicht zu unterstützen.<br />',
				'Für mehr Informationen klicken sie <a href="http://get.webgl.org/">hier</a>.<br><br>' + supp_browser + '</div>'
			].join( '\n' ) : [
				'<div>Ihr Browser scheint <a href="http://khronos.org/webgl/wiki/Getting_a_WebGL_Implementation">WebGL</a> nicht zu unterstützen.<br/>',
				'Für mehr Informationen klicken sie <a href="http://get.webgl.org/">hier</a>.<br><br>' + supp_browser + '</div>'
			].join( '\n' );

		}

		return element;

	},

	addGetWebGLMessage: function ( parameters ) {

		var parent, id, element;

		parameters = parameters || {};

		parent = parameters.parent !== undefined ? parameters.parent : document.body;
		id = parameters.id !== undefined ? parameters.id : 'oldie';

		element = Detector.getWebGLErrorMessage();
		element.id = id;

		parent.appendChild( element );

	}

};

// browserify support
if ( typeof module === 'object' ) {

	module.exports = Detector;

}

